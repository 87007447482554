import SortIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/sort.svg';
import { BannerCard } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import HeadlinesBanner from 'assets/images/headlines_banner.svg';
import { ItemVariant7 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant7';
import { getResearchAggregates, getCompanyHeadlines, getAvailableResearchTypesForCompany } from '../api/company';
import { CompanyParams, MenuItemState } from '../types/company';
import { ScreenerResearchData, DataItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    getScreenerInfiniteOptionsProps,
    getScreenerInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/screener';
import { Box, Theme } from '@mui/material';
import { ETFMenu, CFRABadge, CustomCheckbox } from '@cfra-nextgen-frontend/shared';
import moment from 'moment';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { companyHeadlineItemTheme, BadgeThemeWithMenu, BadgeMenuItemStyle } from 'components/themes/theme';
import { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { MenuItemProps } from '@cfra-nextgen-frontend/shared/src/components/ETFMenu';
import { ReactComponent as IconUnchecked } from '@cfra-nextgen-frontend/shared/src/assets/icons/iconunchecked.svg';
import { ReactComponent as IconChecked } from '@cfra-nextgen-frontend/shared/src/assets/icons/iconchecked.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { ResearchReportTypes } from 'utils/enums';
import { LinkGeneratorModes } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { LISTBOX_PADDING } from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

const getRowComponent = ({ rowData }: { rowData: any }) => {
    const researchId = rowData.research_report._id;
    const publishDate = moment(rowData.research_report.publish_timestamp).format('MMM DD, YYYY');
    const title = rowData.research_report.title;
    const reportType = rowData.research_report.research_type_name;
    const reportTypeID = rowData.research_report.research_type_id;
    const hasModalFeature = reportTypeID === ResearchReportTypes.SpecialInterest;

    const linkParams = researchLinkGetterParams[0];
    linkParams.mode = hasModalFeature ? LinkGeneratorModes.ExtractLink : LinkGeneratorModes.OpenInNewTab;
    const hasLock = rowData.research_report.s3_pdf_url === '';

    return (
        <ItemVariant7<ScreenerResearchData>
            handleOuterSetRequestParamsProps={(setRequestParamsProps) =>
                setRequestParamsProps(getRequestParamsPropsVariant1(researchId))
            }
            firstRowItems={[reportType, publishDate]}
            secondRowText={title}
            useLinkGetterParams={researchLinkGetterParams}
            itemTheme={companyHeadlineItemTheme}
            hasModalFeature={hasModalFeature}
            hasLock = {hasLock}
        />
    );
};

const getIcon = (icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) => (
    <CustomSvgIcon
        component={icon}
        viewBox='0 0 18 18'
        sx={{
            width: '18px',
            height: '18px',
        }}
    />
);

const UncheckedIcon = getIcon(IconUnchecked);
const CheckedIcon = getIcon(IconChecked);

export const getMenuItems = (reports: Array<MenuItemState>, onChange: any): Array<MenuItemProps> => {
    return reports.map((report) => {
        return {
            itemName: (
                <>
                    <CustomCheckbox
                        icon={UncheckedIcon}
                        checkedIcon={CheckedIcon}
                        name={report.name}
                        checked={report.isChecked}
                        sx={{ marginRight: '11px' }}
                    />
                    {report.name + ' '}
                    <Box sx={{ color: '#007AB9', fontWeight: 500, paddingLeft: '10px' }}>{report.count}</Box>
                </>
            ),
            callback: (e) => onChange(e, report),
        };
    });
};

export const getFilterComponent = (menuItems: Array<MenuItemProps>, checkedCount: number, maxDisplaySize = 7) => {
    const itemCount = menuItems.length;
    const itemSize = 42;
    const height = (itemCount >= maxDisplaySize ? maxDisplaySize : itemCount) * itemSize + 2 * LISTBOX_PADDING;
    return (
        menuItems.length > 0 && (
            <ThemeProvider theme={BadgeThemeWithMenu} key='getfilterComponent'>
                <ETFMenu.ETFMenu
                    key='companyHeadlinesFilter'
                    menuStyles={{ minWidth: '190px', flexWrap: 'wrap', height }}
                    menuItemStyles={{
                        ...BadgeMenuItemStyle,
                    }}
                    keepOpen={true}
                    paperProps={{
                        borderTop: '11px solid white',
                        borderBottom: '11px solid white',
                        ...scrollbarThemeV3,
                    }}
                    menuItems={menuItems}
                    addBorderBetweenItems={false}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <CFRABadge badgeContent={checkedCount} color='primary'>
                        <Box component='img' src={SortIcon} height='auto' />
                    </CFRABadge>
                </ETFMenu.ETFMenu>
            </ThemeProvider>
        )
    );
};

const size = 10;
const scrollThresholdPx = Math.round((71 * size) / 2); // take 50% of fetching size, assume each item height is 71px

export function CompanyHeadlines({
    queryParams,
    isLoading,
    gics_code,
    customTheme
}: {
    queryParams: CompanyParams;
    isLoading: boolean;
    gics_code: string;
    customTheme?: Theme;
}) {
    const [reports, setReports] = useState<Array<MenuItemState>>([]);
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const availableResearchTypes = sendSingleRequest?.(getAvailableResearchTypesForCompany(queryParams, gics_code), {
        requestType: RequestTypes.POST,
        path: 'research/screener-filters',
        queryKeyFirstElement: 'getAvailableResearchTypes',
        apiName: ApiNames.Research,
    });

    const OnChange = (_e: any, _report: { name: string }) => {
        const newReportState = reports.map((report) => {
            if (report.name === _report.name) {
                report.isChecked = !report.isChecked;
            }
            return report;
        });
        setReports(newReportState);
    };

    useEffect(() => {
        if (availableResearchTypes?.isSuccess) {
            const allReports = getResearchAggregates(availableResearchTypes?.data);
            setReports(
                allReports.map((report) => {
                    return { key: report.key, name: report.value, isChecked: false, count: report.count };
                }),
            );
        }
    }, [availableResearchTypes?.isSuccess, availableResearchTypes?.data]);

    const outerContainerRef = useRef<HTMLDivElement | null>(null);

    // it is necessary to keep the OptionsContainer in useMemo to make infinite scroll work
    const OptionsContainer = useMemo(() => {
        return getOptionsContainer({
            setOuterContainerRef: (node) => (outerContainerRef.current = node),
            containerSx: {
                paddingTop: '10px',
                paddingRight: '5px',
                overflowX: 'hidden',
                width: '100%'
            },
            scrollableAreaContainerSx: {
                padding: '0px 0px 0px 12px',
            },
        });
    }, []);

    return (
        <Grid container sx={(theme) => ({ 
            height: queryParams.enableQuery ? { lg: '702px', xs: '730px' } : '180px',
            [theme.breakpoints.between(375, 550)]: {
                height: queryParams.enableQuery ? '650px' : '180px'
            }
        })}>
            <Grid item sx={{ width: '100%' }}>
                <BannerCard
                    title={'Company Headlines'}
                    customTheme={customTheme}
                    bannerImage={HeadlinesBanner}
                    isLoading={isLoading}
                    noResults={!queryParams.enableQuery}
                    headerComponents={[
                        getFilterComponent(
                            getMenuItems(reports, OnChange),
                            reports.filter((report) => report.isChecked).length,
                        ),
                    ]}
                    childrenContainerSx={{ margin: '0px 0px' }}>
                    {
                        <Box sx={{ display: 'flex', height: queryParams.enableQuery ? { lg: '545px', md: '585px', sm: '430px', xs: '480px' } : '180px', width: '100%' }}>
                            <InfiniteOptions<ScreenerResearchData, DataItem>
                                outerContainerRef={outerContainerRef}
                                infiniteRequestParams={[
                                    getCompanyHeadlines(
                                        queryParams,
                                        reports.filter((report) => report.isChecked).map((report) => report.name),
                                        gics_code,
                                    ),
                                    {
                                        queryKeyFirstElement: 'getCompanyHeadlines',
                                        ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research', 100),
                                    },
                                ]}
                                OptionsContainer={OptionsContainer}
                                scrollThresholdPx={scrollThresholdPx}
                                RowComponent={getRowComponent}
                                noResultsFoundBoxSxProps={{ paddingLeft: '17px' }}
                                {...getScreenerInfiniteOptionsProps<'research'>('research')}
                            />
                        </Box>
                    }
                </BannerCard>
            </Grid>
        </Grid>
    );
}
