import { Box, styled } from '@mui/material';
import { useRef, useCallback } from 'react';
import { ReactComponent as LockIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/entitlement-lock.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { TypographyStyle7 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { PopupVariant1ModalSx } from '@cfra-nextgen-frontend/shared/src/components/Popup/PopupVariant1';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';

export const LockIconComponent = ({
    modalOpen,
    setModalOpen,
    disableModalPortal = false,
}: {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    disableModalPortal?: boolean;
}) => {
    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const modalRef = useRef(null);

    return (
        <Box
            component='span'
            sx={{
                marginRight: '5px',
                cursor: 'pointer'
            }}>
            <CustomSvgIcon
                component={LockIcon}
                viewBox='0 0 68 68'
                sx={{
                    '&:hover': {
                        fill: '#007AB9'
                    },
                }}
                onClick={() => setModalOpen(true)}
            />
            <ETFModal
                disableModalPortal = {disableModalPortal}
                getOpenComponent={() => <></>}
                title='Not Entitled'
                closeButtonFontSize={22}
                modalBoxStyles={{ ...PopupVariant1ModalSx.modalBoxStyles, width: '490px' }}
                descriptionStyle={{
                    boxShadow: 'none',
                }}
                titleStyle={{ ...PopupVariant1ModalSx.titleStyle, boxShadow: 'none' }}
                dateStyle={{
                    boxShadow: 'none',
                    color: '#A09F9F',
                    fontSize: '12px',
                    paddingBottom: '0',
                    lineHeight: '1.5em',
                }}
                zIndex={5000}
                externalOpenModal={modalOpen}
                keepContentOnClose={false}
                callbackOnClose={handleCloseModal}
                ref={modalRef}>
                {[<LockModalContent key='lock-modal-content' />]}
            </ETFModal>
        </Box>
    );
};

const StyledTypography = styled(TypographyStyle7)(() => ({ lineHeight: '22px', paddingTop: '2px' }));

const LockModalContent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }}>
            <StyledTypography>
                Your subscription does not include access to this content. Please contact your sales representative or
                CFRA via Phone or Email.
            </StyledTypography>
            <ul>
                <li>
                    <StyledTypography>Phone: 1 800.220.0502</StyledTypography>
                </li>
                <li>
                    <StyledTypography sx={{ display: 'inline-block' }}>Email:</StyledTypography>
                    <StyledTypography sx={{ display: 'inline-block' }}>
                        <a
                            style={{ textDecoration: 'none', paddingLeft: '4px', display: 'inline-block' }}
                            href='mailto:cservices@cfraresearch.com'>
                            cservices@cfraresearch.com
                        </a>
                    </StyledTypography>
                </li>
            </ul>
        </Box>
    );
};
