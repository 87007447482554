import MoreInsightsBanner from '@cfra-nextgen-frontend/shared/src/assets/images/MoreInsights.png';
import { BannerCard } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import {
    DataItem,
    ScreenerDataWithGenericResultsKey,
    ScreenerResearchData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import { moreInsightsItemTheme } from 'components/themes/theme';
import {
    getScreenerInfiniteOptionsProps,
    getScreenerInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/screener';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { SideBarComponentRef } from './utils';
import { ItemVariant9 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant9';

const size = 10;
const scrollThresholdPx = Math.round((71 * size) / 2); // take 50% of fetching size, assume each item height is 71px


const RowComponent = ({ rowData }: { rowData: any }) => {
    const publishDate = moment(rowData.research_report.publish_timestamp).format('MMM DD, YYYY');
    const title = rowData.research_report.title;
    const reportType = rowData.research_report.research_type_name;
   
    return (
        <ItemVariant9
            onClick={() => {}}
            firstRowItems={[reportType, publishDate]}
            secondRowText={title}
            itemTheme={moreInsightsItemTheme}
            hasLock ={true}
            buttonSx={{
                position: 'relative',
            }}/>
    );
};

export const MoreInsights = forwardRef<SideBarComponentRef, {}>((props, ref) => {
    const [isLoading, setIsLoading] = useState(true);
    const [numberOfResults, setNumberOfResults] = useState(0);

    const { filtersPostData } = useContext(FiltersModalContext);

    const [externalSearchByParams, setExternalSearchByParams] = useState<SearchByParams | undefined>({
        requestBody: undefined,
        search: '',
    });

    useImperativeHandle(ref, () => ({
        updateSideBarSearchByParams: (newSearchByParams) => {
            setExternalSearchByParams((previousSearchByParams) => ({
                ...previousSearchByParams,
                ...newSearchByParams,
            }));
        },
    }));

    useEffect(() => {
        setExternalSearchByParams((previousSearchByParams) => ({
            ...previousSearchByParams,
            requestBody: filtersPostData,
        }));
    }, [filtersPostData]);

    const completeRequestBody = useMemo(() => {
        return getFiltersReqBody(externalSearchByParams?.requestBody);
    }, [externalSearchByParams?.requestBody]);

    const searchByParamsExtendedCommon = useMemo(() => {
        return {
            from: 0,
            securityType: 'research',
            view: 'unentitled',
            viewMode: 'unentitled',
        };
    }, []);

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }
    }, [sendSingleRequest]);

    const requestConfig = getScreenerInfiniteRequestParamsConfig<'research'>(10, 'research', 10);

    const isContentAvailable =
        (sendSingleRequest?.<ScreenerDataWithGenericResultsKey<'results'>>(
            {
                ...searchByParamsExtendedCommon,
                size: 1,
                config: {
                    enabled: true,
                },
            },
            {
                ...requestConfig,
                queryKeyFirstElement: 'checkMoreInsightsContentAvailable',
                path: 'research/screener',
            },
        )?.data?.results?.total || 0) > 0;

    const outerContainerRef = useRef<HTMLDivElement | null>(null);

    // it is necessary to keep the OptionsContainer in useMemo to make infinite scroll work
    const OptionsContainer = useMemo(() => {
        return getOptionsContainer({
            setOuterContainerRef: (node) => (outerContainerRef.current = node),
            containerSx: {
                paddingTop: '5px',
                paddingRight: '5px',
                overflowX: 'hidden',
                width: '100%',
            },
            scrollableAreaContainerSx: {
                padding: '0px 0px 0px 12px',
            },
        });
    }, []);

    const isNoResults = useMemo(() => {
        return !isLoading && numberOfResults === 0;
    }, [numberOfResults, isLoading]);

    const isInitialLoading = useMemo(() => {
        return isLoading && numberOfResults === -1;
    }, [numberOfResults, isLoading]);

    // don't show More Insights card if there is no unentitled content available
    // check without filters
    if (!isContentAvailable) {
        return null;
    }

    return (
        <Grid container sx={{ width: '100%', maxHeight: '702px' }}>
            <Grid item xs={12}>
                <BannerCard
                    outerNoResultText={'No content available.'}
                    loadingIndicatorSx={{ padding: '0px 20px' }}
                    title={'More Insights'}
                    bannerImage={MoreInsightsBanner}
                    bannerImageSx={{
                        maxHeight: '110px',
                    }}
                    isLoading={isLoading}
                    noResults={isNoResults}
                    childrenContainerSx={{ margin: '0px 0px' }}>
                    {
                        <Box
                            sx={{
                                display: 'flex',
                                height: isNoResults || isInitialLoading ? '180px' : '545px',
                                maxHeight: '545px',
                                paddingTop: '0px',
                            }}>
                            <InfiniteOptions<ScreenerResearchData, DataItem>
                                setIsLoading={setIsLoading}
                                setNumberOfResults={setNumberOfResults}
                                outerContainerRef={outerContainerRef}
                                infiniteRequestParams={[
                                    {
                                        ...searchByParamsExtendedCommon,
                                        ...externalSearchByParams,
                                        requestBody: completeRequestBody,
                                        size: 10,
                                        orderBy: 'research_report.publish_timestamp',
                                        sortDirection: 'desc' as 'desc',
                                        path: 'research/screener',
                                        config: {
                                            enabled: true,
                                        },
                                    },
                                    {
                                        ...requestConfig,
                                        queryKeyFirstElement: 'getMoreInsights',
                                    },
                                ]}
                                OptionsContainer={OptionsContainer}
                                scrollThresholdPx={scrollThresholdPx}
                                RowComponent={RowComponent}
                                noResultsFoundBoxSxProps={{ display: 'none' }}
                                loadingContainerStyles={{ display: 'none' }}
                                {...getScreenerInfiniteOptionsProps<'research'>('research')}
                            />
                        </Box>
                    }
                </BannerCard>
            </Grid>
        </Grid>
    );
});
