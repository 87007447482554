import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { Box, CssBaseline, SxProps, Theme, ThemeProvider, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import React, { Fragment, useCallback, useState } from 'react';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';


export type ItemVariant9BaseProps = {
    firstRowItems: Array<string | undefined>;
    innerRef?: React.Ref<HTMLButtonElement>;
    secondRowText: string;
    itemTheme: Theme;
    hasModalFeature?: boolean;
};

type ItemVariant9Props = ItemVariant9BaseProps & {
    onClick: () => void;
    extractedLink?: string;
    buttonSx?: SxProps;
    hasLock?: boolean;
};

export function ItemVariant9({
    firstRowItems,
    innerRef,
    secondRowText,
    itemTheme,
    hasModalFeature,
    onClick,
    extractedLink,
    buttonSx,
    hasLock = false
}: ItemVariant9Props) {
    const [modalOpen, setModalOpen] = useState(false);

  
    const onClickLocal = useCallback(() => {
        if (hasLock) {
            if (!modalOpen) setModalOpen(true);
        } else {
            onClick();
        }
    }, [onClick, hasLock, modalOpen]);

    return (
        <>
            <ThemeProvider theme={itemTheme}>
                <CssBaseline />
                <ButtonBase ref={innerRef} onClick={onClickLocal} sx={buttonSx}>
                    {/* First Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            paddingBottom: '3px',
                        }}>
                        {firstRowItems.map(
                            (item, index) =>
                                item && (
                                    <Fragment key={item}>
                                        <Typography variant={index === 0 ? 'researchType' : 'caption'} key={item}>
                                            {item}
                                        </Typography>
                                        {index < firstRowItems.length - 1 && <DividerStyle1 />}
                                    </Fragment>
                                ),
                        )}
                    </Box>
                    {/* Second Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}>
                        {hasLock && (
                            <LockIconComponent
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                            />
                        )}
                        <Typography variant='headlineTitle'>{secondRowText}</Typography>
                    </Box>
                </ButtonBase>
            </ThemeProvider>
        </>
    );
}
