import { TypographyStyle28 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, SxProps } from '@mui/material';

interface ProfilePictureProps {
    imageUrl: string;
    firstName: string;
    lastName: string;
    width: string;
    height: string;
    avatarDim: string;
    typographySx?: SxProps;
}

const positionCenterSx: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const gradients = {
    '0': ['#5E9EFF', '#832AFF'],
    '1': ['#4A95FA', '#26E9E3'],
    '2': ['#DFF310', '#4CC028'],
    '3': ['#FF65D5', '#FFBA6E'],
};

const getCombinedCharCodes = (name: string): number => {
    return name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

export function ProfilePicture(props: ProfilePictureProps) {
    const { width, height, avatarDim, typographySx = {}, imageUrl = '', firstName = '', lastName = '' } = props;

    const profileSx: SxProps = {
        width,
        height,
        borderRadius: '20px',
        objectFit: 'cover',
        objectPosition: '50% 0',
    };

    if (imageUrl) {
        return <Box component='img' src={imageUrl} alt='avatar' sx={profileSx} />;
    }

    const combinedCharCodes = (getCombinedCharCodes(firstName) + getCombinedCharCodes(lastName)) % 4;
    const profileGradient = gradients[combinedCharCodes.toString() as keyof typeof gradients];
    const circleFrameSx: SxProps = {
        width: avatarDim,
        height: avatarDim,
        borderRadius: '50%',
        position: 'relative',
        backgroundImage: `linear-gradient(135deg, ${profileGradient[0]}, ${profileGradient[1]})`,
    };

    return (
        <Box sx={circleFrameSx}>
            <Box sx={positionCenterSx}>
                <TypographyStyle28 sx={{ lineHeight: '48.4px', ...typographySx }}>
                    {firstName?.[0] + lastName?.[0]}
                </TypographyStyle28>
            </Box>
        </Box>
    );
}
