import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import Box from '@mui/material/Box';
import { Typography } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { PageTheme } from 'components/themes/theme';
import { HideOnSmAndBelow } from 'utils/responsive';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';
import { useState } from 'react';

const getFormattedValue = (value: number) => (value === null || value === undefined ? 'NA' : value.toString());

export type CFRAScoresProps = {
    title: string;
    color: string;
    value: any;
    date: string;
    textTitle: string;
    ticker: string;
    handleClick: () => void;
    hasLock: boolean
};
export function CFRAScores(stackContent: CFRAScoresProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const reportClick = () => {
        if (stackContent.hasLock) {
            setModalOpen(true);
        } else {
            stackContent.handleClick();
        }
    };

    const scoreDetail = `Scores indicate decile rank relative to index or region. A decile score of 1 indicates lower ${stackContent.textTitle}, while a 10 indicates higher ${stackContent.textTitle}.`;
    return (
        <Grid container>
            <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
                <Typography variant='cardSubCaption' sx={{ lineHeight: '28px', letterSpacing: '0.1%' }}>
                    {'CFRA ' + stackContent.title + (!stackContent.title.endsWith('Score') ? ' Score' : '')}
                </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
                <Box
                    sx={{
                        border: '2px solid ' + stackContent.color,
                        width: '114px',
                        height: '114px',
                        borderRadius: 2,
                    }}>
                    <Stack sx={{ width: '100%', textAlign: 'center', padding: '5px 0px' }} key={stackContent.title}>
                        <Typography variant='scoreText'>
                            <div style={{ textTransform: 'uppercase' }}>cfra</div>
                            <div style={{ textTransform: 'uppercase' }}>{stackContent.title}</div>
                        </Typography>
                        <Typography
                            variant='scoreValue'
                            sx={{
                                color: stackContent.color,
                                fontWeight: 600,
                            }}>
                            {getFormattedValue(stackContent.value)}
                        </Typography>
                        <Typography variant='scoreDate' sx={{ textTransform: 'uppercase' }}>
                            {stackContent.date}
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: { sm: 'none' } }}>

                <Typography variant='cardDescriptionTitle' sx={{ paddingBottom: '5px', fontWeight: 500 }}>
                    Score Detail
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <ETFLinkButton
                        onClick={reportClick}
                        text='Diagnostic Report:'
                        endIcon={
                            <CustomSvgIcon
                                component={PdfIcon}
                                viewBox='0 0 18 18'
                                sx={{
                                    width: '18px',
                                    fill: '#002B5A',
                                    '&:hover': {
                                        fill: '#007AB9',
                                    },
                                }}
                            />
                        }
                        sx={{
                            ...PageTheme.typography.cardLink,
                            lineHeight: {
                                xs: '20px',
                                sm: '36px',
                            },
                            textAlign: 'left',
                        }}
                    />
                    {stackContent.hasLock && <LockIconComponent modalOpen={modalOpen} setModalOpen={setModalOpen} />}
                </Box>
            </Grid>
            <Grid item sm={9} xs={12}>
                
                <Stack sx={{ textAlign: 'left' }}>
                    <HideOnSmAndBelow>
                        <Typography variant='cardDescriptionTitle' sx={{ paddingBottom: '5px', fontWeight: 500 }}>
                            Score Detail
                        </Typography>
                    </HideOnSmAndBelow>
                    <Typography
                        variant='cardDescriptionText'
                        sx={{ fontWeight: 400, paddingTop: { xs: '10px', sm: '0px' } }}>
                        {scoreDetail}
                    </Typography>
                    <HideOnSmAndBelow>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                            <ETFLinkButton
                                onClick={reportClick}
                                text='Diagnostic Report:'
                                endIcon={
                                    <CustomSvgIcon
                                        component={PdfIcon}
                                        viewBox='0 0 18 18'
                                        sx={{
                                            width: '18px',
                                            fill: '#002B5A',
                                            '&:hover': {
                                                fill: '#007AB9',
                                            },
                                        }}
                                    />
                                }
                                sx={{
                                    ...PageTheme.typography.cardLink,
                                }}
                            />
                            {stackContent.hasLock && (
                                <LockIconComponent modalOpen={modalOpen} setModalOpen={setModalOpen} />
                            )}
                        </Box>
                    </HideOnSmAndBelow>
                </Stack>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '15px' }}>
                <Typography variant='cardDescriptionTitle' sx={{ fontWeight: 500 }}>
                    {`Previous Accrual Deciles for ${stackContent.ticker}`}
                </Typography>
            </Grid>
        </Grid>
    );
}
